<template>
  <div>
    <v-card outlined class="mx-auto pa-6 my-12">
      <div class="d-flex justify-space-around mb-6">
        <div v-for="conteudo in conteudos.slice(0, 5)" :key="conteudo.item">
          <i class="grey--text">{{ conteudo.label }}:</i>
          <br />
          {{ acessarConteudo(setor, conteudo) }}
        </div>
      </div>
      <v-divider class="mx-5"></v-divider>
      <div class="d-flex justify-space-around mt-3 mb-6">
        <div>
          <i class="grey--text">Alocacao:</i>
          <br />
          {{ setor.fila ? 'Fila' : 'Aleatório' }}
        </div>
        <div v-for="conteudo in conteudos.slice(5, 8)" :key="conteudo.item">
          <i class="grey--text">{{ conteudo.label }}:</i>
          <br />
          {{ vagas(setor.vagas, conteudo.item) }}
        </div>
      </div>
      <v-divider class="mx-4"></v-divider>
      <div class="d-flex flex-wrap pa-6 mx-6">
        <div
          class="d-flex flex-column mx-2 borded"
          style="width: 100px; cursor: pointer"
          v-for="vaga in setor.vagas.filter((vaga) => vaga.ativo)"
          :key="vaga.id"
        >
          <v-icon   x-large :color="vaga.livre ? 'green' : 'red'">
            mdi-truck
          </v-icon>
          <v-btn
            class="text-caption text-center"
            @click="open(vaga.public_id_agendamento)"
            >{{ vaga.placa || vaga.descricao }}</v-btn
          >
        </div>
      </div>
    </v-card>
    <v-dialog v-model="dialog_info" width="1000">
      <v-card text-align="center">
        <v-card-title class="text-h5">
          Informações sobre o Agendamento
        </v-card-title>
        <v-card-text>
          <Info :agendamento="agendamento" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeInfoJanela"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
 
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Info from '@/components/logistica/fluxo/Info.vue'
export default {
  name: 'DiagramaSetor',
  components: { Info },
  data() {
    return {
      dialog: false,
      tab: null,
      dialog_info: false,
      agendamento: null,
      conteudos: [
        { item: 'descricao', data: null, label: 'Setor', default: '-' },
        {
          item: 'fornecedor',
          data: 'descricao',
          label: 'Fornecedor',
          default: 'Nenhum',
        },
        {
          item: 'terminal',
          data: 'descricao',
          label: 'Terminal',
          default: 'Nenhum',
        },
        {
          item: 'produto',
          data: 'descricao',
          label: 'Produto',
          default: 'Nenhum',
        },
        {
          item: 'subProduto',
          data: 'descricao',
          label: 'Sub Produto',
          default: 'Nenhum',
        },
        { item: 'Total', data: null, label: 'Vagas Totais', default: 0 },
        { item: 'Ocupadas', data: null, label: 'Vagas Ocupadas', default: 0 },
        { item: 'Livres', data: null, label: 'Vagas Livres', default: 0 },
      ],
    }
  },
  created() {
    if (
      this.$route.params.estacionamento == undefined ||
      this.$route.params.estacionamento == 0
    ) {
      this.$router.push({
        name: 'Estacionamento',
      })
    } else {
      if (
        this.$route.params.setor == undefined ||
        this.$route.params.setor == 0
      ) {
        this.$router.push({
          name: 'Setores',
          params: {
            estacionamento: this.$route.params.estacionamento,
          },
        })
      } else {
        this.atualizarSetor()
      }
    }
  },
  computed: {
    ...mapState('patio', ['setor']),
  },
  methods: {
    ...mapActions('patio', ['getAgendamentoGerenciamentoServico']),
    ...mapActions('patio', ['getSetor']),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    closeInfoJanela() {
      this.dialog_info = false
    },
    async atualizarSetor() {
      try {
        await this.getSetor({
          setor_public_id: this.$route.params.setor,
          estacionamento_public_id: this.$route.params.estacionamento,
        })
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa não possui acesso ao setor.')
        } else {
          this.errorMessage(error.response.data)
        }
      }
    },
    acessarConteudo(item, conteudo) {
      if (conteudo.data == null)
        return item[conteudo.item] ? item[conteudo.item] : conteudo.default
      return item[conteudo.item]
        ? item[conteudo.item][conteudo.data]
        : conteudo.default
    },
    async open(params) {
      let item = { public_id: "" }
      item.public_id = params

      this.agendamento = await this.getAgendamentoGerenciamentoServico(item)
      this.$nextTick(() => {
        this.dialog_info = true
      })
    },
    vagas(vagas = [], value) {
      if (value == 'Total') {
        return vagas.filter((vaga) => vaga.ativo).length
      }
      if (value == 'Ocupadas') {
        return vagas.filter((vaga) => vaga.ativo && !vaga.livre).length
      }
      if (value == 'Livres') {
        return vagas.filter((vaga) => vaga.ativo && vaga.livre).length
      }
      return 0
    },
  },
}
</script>
