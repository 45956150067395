<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
      :to="breadcrumbs.to"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <diagrama-setor></diagrama-setor>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>

import DiagramaSetor from '../../components/patio/Setor/DiagramaSetor.vue'

export default {
  data() {
    return {
      tab: null,
      page: {
        title: 'Setor',
      },
      breadcrumbs: [
        {
          text: 'Estacionamento',
          disabled: false,
          to: '/patio/Estacionamento',
        },
        {
          text: 'Setores',
          disabled: false,
          to: {
            name: 'Setores',
            params: {
              estacionamento: this.$route.params.estacionamento
            }
          },
        },
        {
          text: 'Setor',
          disabled: false,
          
        },
      ],
    }
  },
  components: {
    DiagramaSetor
  },
}
</script>